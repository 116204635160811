import React from 'react'
import PlayStore from '../images/badges/google-play-badge.png'
import PlayStoreWebp from '../images/badges/google-play-badge.webp'
import AppStore from '../images/badges/app-store-badge.png'
import AppStoreWebp from '../images/badges/app-store-badge.webp'

export interface AppBadgeConfig {
  type: 'play-store' | 'app-store' | 'website'
  height?: number
}

const AppBadge = (config: AppBadgeConfig): JSX.Element => {
  let badges: { png: string; webp: string; link: string; alt: string }
  if (config.type === 'play-store') {
    badges = {
      png: PlayStore,
      webp: PlayStoreWebp,
      link:
        'https://play.google.com/store/apps/details?id=com.hokishoptaiwan.app',
      alt: 'Download on Google Play Store',
    }
  } else if (config.type === 'app-store') {
    badges = {
      png: AppStore,
      webp: AppStoreWebp,
      link: 'https://apps.apple.com/us/app/hoki-shop/id1550246409',
      alt: 'Download on Apple App Store',
    }
  }

  return (
    <a href={badges.link}>
      <picture>
        <source srcSet={badges.webp} type="image/webp" />
        <source srcSet={badges.png} type="image/png" />
        <img
          src={badges.png}
          alt={badges.alt}
          style={{ height: config.height ?? 40 }}
        />
      </picture>
    </a>
  )
}

export default AppBadge
