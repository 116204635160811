import React, { ChangeEvent, useState } from 'react'
import styled from 'styled-components'
import AppBadge from '../components/app-badge'
import Base from '../components/base'
import Footer from '../components/footer'
import Logo from '../components/logo'
import { graphql } from 'gatsby'
import { setPreferredLanguage } from '../lib/site-language'
import { preferredLanguage } from './../lib/site-language'
import { Helmet } from 'react-helmet'

const HeroSection = styled.div`
  padding-top: 30px;
  padding-right: 80px;
  padding-left: 80px;
  padding-bottom: 80px;
  background: url("data:image/svg+xml,%3Csvg width='1500' height='161' viewBox='0 0 1500 161' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M36.25 85.7083L0 70.125V161H1500V0L1463.75 19.4792C1428.75 38.9583 1357.5 77.9167 1286.25 97.3958C1213.75 116.875 1142.5 116.875 1071.25 112.979C1035.62 111.031 1000 108.109 964.375 105.188C928.75 102.266 893.125 99.3438 857.5 97.3958C786.25 93.5 713.75 93.5 642.5 101.292C595 106.486 547.5 115.144 500 123.801C476.25 128.13 452.5 132.458 428.75 136.354C357.5 148.042 286.25 155.833 213.75 144.146C142.5 132.458 71.25 101.292 36.25 85.7083Z' fill='white'/%3E%3C/svg%3E%0A"),
    url("data:image/svg+xml,%3Csvg width='734' height='763' viewBox='0 0 734 763' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M355.215 0.120342C430.96 -2.73616 498.546 45.7241 555.251 96.0353C607.763 142.625 633.165 207.654 661.517 271.881C693.545 344.439 747.989 416.053 730.68 493.455C712.973 572.636 638.308 623.132 572.394 670.424C506.864 717.441 435.82 760.282 355.215 762.845C273.066 765.456 192.674 734.994 127.768 684.559C64.2672 635.216 2.42572 567.255 0.0438144 486.859C-2.23668 409.887 85.1299 364.207 116.189 293.746C142.325 234.453 124.939 160.97 165.845 110.72C213.156 52.602 280.338 2.94407 355.215 0.120342Z' fill='url(%23paint0_linear)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='367' y1='0' x2='367' y2='763' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%230094E7'/%3E%3Cstop offset='1' stop-color='%230097EC' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A"),
    linear-gradient(180deg, #00a6da 0%, #3fbaff 100%);
  background-repeat: no-repeat;
  background-position: 50% calc(100% + 5px), calc(100% + 200px) 75%, center;
  background-size: contain, 600px auto, 100%;
  width: 100%;
  color: #fff;
  font-family: 'Alegreya Sans', --apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  img {
    pointer-events: none;
  }

  @media screen and (max-width: 750px) {
    flex-direction: column;
    padding-right: 40px;
    padding-left: 40px;
  }

  @media screen and (max-width: 600px) {
    background-position: 0 calc(100% + 5px), calc(100% + 100px) 75%, center;
    background-size: contain, 300px auto, 100%;
  }

  @media screen and (max-width: 550px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

const AppBadgeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 10px;
  margin-top: 10px;
`

const HeroImageContainer = styled.div`
  width: 100%;
  height: 475px;
  max-width: 325px;
  flex-shrink: 0;
  text-align: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  @media screen and (max-width: 750px) {
    align-self: center;
  }
`

const Constraint = styled.div`
  display: flex;
  column-gap: 30px;
  row-gap: 20px;
  max-width: 1080px;
  margin: 0 auto;
  @media screen and (max-width: 750px) {
    flex-direction: column;
  }
`

const Heading = styled.h1`
  margin-top: 40px;
`

const Subtitle = styled.h2`
  font-weight: normal;
`

const CtaBlock = styled.div`
  margin-top: 60px;
`

const LanguageSelectorContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 10px;
  max-width: 1080px;
  margin: 0 auto;
  margin-bottom: 15px;
`

const Select = styled.select`
  border-color: #fff;
  border-radius: 20px;
  color: #fff;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='10' viewBox='0 0 20 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.17658 9.712C9.26833 9.80082 9.39109 9.87344 9.5343 9.92362C9.67751 9.97379 9.83686 10 9.99864 10C10.1604 10 10.3198 9.97379 10.463 9.92362C10.6062 9.87344 10.729 9.80082 10.8207 9.712L19.8214 1.04593C19.9256 0.945973 19.9867 0.828898 19.9981 0.707422C20.0094 0.585946 19.9706 0.464717 19.8859 0.356905C19.8012 0.249093 19.6737 0.158821 19.5174 0.0958989C19.3611 0.0329764 19.1819 -0.000190883 18.9993 8.26379e-07H0.99794C0.815769 0.000502398 0.63725 0.0340962 0.48158 0.0971694C0.32591 0.160243 0.19898 0.250409 0.114439 0.357972C0.0298985 0.465534 -0.00905387 0.586424 0.00177094 0.707639C0.0125957 0.828855 0.0727882 0.945811 0.175875 1.04593L9.17658 9.712Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) 50%;
  background-size: 12px 6px;
  appearance: none;
  outline: none;
  padding: 8px 20px;
  padding-right: 42px;

  &:focus {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='10' viewBox='0 0 20 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.8234 0.288C10.7317 0.199179 10.6089 0.126555 10.4657 0.0763837C10.3225 0.0262127 10.1631 -8.59966e-07 10.0014 -8.74109e-07C9.83957 -8.88253e-07 9.68022 0.0262126 9.53701 0.0763836C9.3938 0.126555 9.27104 0.199179 9.17929 0.288L0.178585 8.95407C0.0744018 9.05403 0.0133078 9.1711 0.00193793 9.29258C-0.00943193 9.41405 0.0293571 9.53528 0.114094 9.64309C0.198831 9.75091 0.326274 9.84118 0.482574 9.9041C0.638875 9.96702 0.818056 10.0002 1.00065 10L19.0021 10C19.1842 9.9995 19.3628 9.9659 19.5184 9.90283C19.6741 9.83976 19.801 9.74959 19.8856 9.64203C19.9701 9.53447 20.0091 9.41358 19.9982 9.29236C19.9874 9.17114 19.9272 9.05419 19.8241 8.95407L10.8234 0.288Z' fill='white'/%3E%3C/svg%3E%0A");
  }

  option {
    color: #333;
    padding: 10px 10px;
  }
`

export const query = graphql`
  query IndexImageQuery {
    hero: file(relativePath: { eq: "phone.png" }) {
      childImageSharp {
        fluid(fit: CONTAIN, background: "rgba(0,0,0,0)") {
          src
          srcWebp
          srcSet
          srcSetWebp
        }
      }
    }
  }
`

const supportedLanguages = {
  en: 'English',
  id: 'Bahasa Indonesia',
}

const LanguageSelector = ({
  currentLanguage: current,
  changeLanguage,
}: {
  currentLanguage: string
  changeLanguage: React.Dispatch<React.SetStateAction<string>>
}) => {
  const options = Object.keys(supportedLanguages).map((code) => (
    <option value={code} key={`select-${code}`}>
      {supportedLanguages[code]}
    </option>
  ))

  const onSelectChange = (event: ChangeEvent) => {
    const lang = (event.target as HTMLSelectElement).value
    setPreferredLanguage(lang)
    changeLanguage(lang)
  }

  return (
    <Select
      name="site-language"
      id="site-language"
      onChange={onSelectChange}
      defaultValue={current}
    >
      {options}
    </Select>
  )
}

const headings = {
  en: 'Access HOKI Shop services in one convenient application',
  id: 'Akses layanan HOKI Shop dalam satu aplikasi praktis',
}

const subtitles = {
  en:
    'HOKI Shop mobile app lets you access various HOKI Shop services from your smartphone.',
  id:
    'HOKI Shop mobile app membantu Anda mengakses layanan HOKI Shop dari smartphone Anda.',
}

const availableOns = {
  en: 'Available now on',
  id: 'Tersedia sekarang di',
}

const IndexPage = ({ data }): JSX.Element => {
  const [language, setLanguage] = useState(preferredLanguage())
  return (
    <Base>
      <Helmet>
        <title>HOKI Shop Mobile App</title>
      </Helmet>
      <HeroSection>
        <LanguageSelectorContainer>
          <svg
            height="18"
            viewBox="0 0 448 417"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M446.33 385.6L356.33 167.6C354.668 163.574 351.848 160.132 348.228 157.71C344.608 155.289 340.35 153.996 335.995 153.996C331.639 153.996 327.382 155.289 323.762 157.71C320.142 160.132 317.322 163.574 315.66 167.6L225.66 385.6C224.541 388.273 223.961 391.142 223.954 394.04C223.946 396.938 224.512 399.809 225.617 402.488C226.723 405.167 228.347 407.601 230.396 409.651C232.446 411.7 234.88 413.325 237.559 414.43C240.237 415.536 243.108 416.102 246.007 416.095C248.905 416.088 251.773 415.509 254.447 414.39C257.12 413.271 259.546 411.635 261.586 409.576C263.625 407.516 265.237 405.074 266.33 402.39L284.66 358H387.33L405.66 402.39C407.322 406.418 410.142 409.861 413.763 412.284C417.384 414.707 421.643 416.001 426 416C429.615 415.998 433.174 415.105 436.362 413.4C439.55 411.695 442.268 409.231 444.277 406.226C446.286 403.22 447.523 399.766 447.88 396.168C448.236 392.571 447.7 388.941 446.32 385.6H446.33ZM302.83 314L336 233.65L369.17 314H302.83Z"
              fill="white"
            />
            <path
              d="M235.84 294.92C239.258 290.199 240.663 284.314 239.746 278.558C238.829 272.803 235.666 267.646 230.95 264.22C230.75 264.07 215.95 253.09 194.46 229.49C234.11 175.81 256.57 114.74 265.73 86H298C303.835 86 309.431 83.6822 313.556 79.5564C317.682 75.4306 320 69.8348 320 64C320 58.1652 317.682 52.5694 313.556 48.4436C309.431 44.3178 303.835 42 298 42H182V22C182 16.1652 179.682 10.5694 175.556 6.44365C171.431 2.31785 165.835 0 160 0C154.165 0 148.569 2.31785 144.444 6.44365C140.318 10.5694 138 16.1652 138 22V42H22C16.1652 42 10.5694 44.3178 6.44365 48.4436C2.31785 52.5694 0 58.1652 0 64C0 69.8348 2.31785 75.4306 6.44365 79.5564C10.5694 83.6822 16.1652 86 22 86H219.25C209.73 112.95 192.2 155.5 165.46 194.36C134.05 152.68 122.38 125.71 122.29 125.49C119.991 120.176 115.689 115.983 110.318 113.819C104.947 111.656 98.9392 111.698 93.5986 113.935C88.258 116.173 84.0148 120.425 81.7892 125.771C79.5636 131.116 79.5352 137.124 81.71 142.49C82.29 143.87 96.26 176.72 134.57 226.42C135.49 227.61 136.4 228.77 137.31 229.93C98.07 274.28 59.57 301.79 43.46 310.67C38.3373 313.464 34.5344 318.179 32.8879 323.777C31.2413 329.375 31.8859 335.397 34.68 340.52C37.4741 345.643 42.1886 349.446 47.7866 351.092C53.3846 352.739 59.4073 352.094 64.53 349.3C66.69 348.12 113.13 322.41 166.16 263.71C188.68 287.79 204.16 299.15 205.09 299.81C207.43 301.51 210.082 302.731 212.895 303.405C215.707 304.078 218.625 304.191 221.481 303.736C224.337 303.281 227.075 302.267 229.539 300.753C232.003 299.238 234.144 297.253 235.84 294.91V294.92Z"
              fill="white"
            />
          </svg>

          <LanguageSelector
            currentLanguage={language}
            changeLanguage={setLanguage}
          />
        </LanguageSelectorContainer>
        <Constraint>
          <div>
            <Logo logoStyle="white" height={60} />
            <Heading>{headings[language]}</Heading>
            <Subtitle>{subtitles[language]}</Subtitle>

            <CtaBlock>
              <div>{availableOns[language]}</div>
              <AppBadgeContainer>
                <AppBadge type="play-store" />
                <AppBadge type="app-store" />
              </AppBadgeContainer>
            </CtaBlock>
          </div>
          <HeroImageContainer>
            {/* <Img fluid={data.hero.childImageSharp.fluid} /> */}
            <picture>
              <source
                srcSet={data.hero.childImageSharp.fluid.srcSetWebp}
                type="image/webp"
              />
              <source
                srcSet={data.hero.childImageSharp.fluid.srcSet}
                type="image/png"
              />
              <img src={data.hero.childImageSharp.fluid.src} alt="Hero" />
            </picture>
          </HeroImageContainer>
        </Constraint>
      </HeroSection>
      <Constraint>
        <Footer />
      </Constraint>
    </Base>
  )
}

export default IndexPage
